<template>
  <b-container class="m-0 w-100" fluid>
    <b-card>
      <validation-observer ref="formMessage" tag="form">
        <b-row>
          <b-col lg="5">
            <b-form-group label="Message in Spanish" label-for="textarea-1">
              <skeleton-card :is-busy="message_es.busy" />
              <validation-provider rules="required" v-slot="{ errors }">
                <b-form-textarea
                  v-model="message_es.message"
                  id="textarea-1"
                  placeholder="Write a message..."
                  :disabled="disableText"
                  :class="{ 'is-invalid': errors[0] }"
                  rows="6"
                  style="height: 300px; max-height: 420px"
                />
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col lg="5">
            <b-form-group label="Message in English" label-for="textarea-2">
              <validation-provider rules="required" v-slot="{ errors }">
                <skeleton-card :is-busy="message_en.busy" />
                <b-form-textarea
                  v-model="message_en.message"
                  id="textarea-2"
                  placeholder="Write a message..."
                  :disabled="disableText"
                  rows="6"
                  style="height: 300px; max-height: 420px"
                  :class="{ 'is-invalid': errors[0] }"
                ></b-form-textarea>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col lg="2">
            <b-row class="d-flex justify-content-center">
              <p style="font-weight: bold">Legend</p>
            </b-row>
            <b-row>
              <b-input-group variant="outline-secondary" size="lg" prepend="@1">
                <b-form-input
                  trim
                  disabled
                  placeholder="FIRST NAME"
                ></b-form-input>
              </b-input-group>

              <b-input-group size="lg" prepend="@2" class="mt-1">
                <b-form-input
                  trim
                  disabled
                  placeholder="LAST NAME"
                ></b-form-input>
              </b-input-group>

              <!-- active switch -->
              <b-form-checkbox
                name="check-button"
                switch
                class="mt-2"
                @change="activeSms()"
                v-model="activeSMS"
              >
                <span class="h4">Active Message</span>
              </b-form-checkbox>
            </b-row>
          </b-col>
        </b-row>
      </validation-observer>
      <b-row>
        <b-col lg="10" class="text-center">
          <div class="d-flex justify-content-center">
            <b-button
              v-if="disableText"
              variant="warning"
              class="mr-1"
              @click="enableText"
            >
              <feather-icon icon="Edit3Icon"> </feather-icon>
              Update</b-button
            >
            <div v-else>
              <b-button variant="info" @click="reset()" class="mr-1">
                <feather-icon icon="Trash2Icon"> </feather-icon>
                Reset</b-button
              >
              <b-button variant="primary" @click="submit()">
                <feather-icon icon="SaveIcon"> </feather-icon>
                SAVE</b-button
              >
            </div>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </b-container>
</template>

<script>
import { mapGetters } from "vuex"
import MessageService from "@/views/ce-digital/sub-modules/settings/views/messages/service/ce-set-messages.service"
import SkeletonCard from "@/views/ce-digital/sub-modules/settings/views/messages/components/no-answer/SkeletonCard.vue"
export default {
  components: { SkeletonCard },
  data() {
    return {
      message_en: {
        id: null,
        message: "",
        busy: true,
        active: false,
      },
      message_es: {
        id: null,
        message: "",
        busy: true,
        active: false,
      },
      disableText: true,
      activeSMS: false,
    }
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  async created() {
    await this.appMessage()
  },
  methods: {
    async appMessage() {
      try {
        let result = await MessageService.getNoAnserSms({
          module_id: 22,
        })
        result.map((item) => {
          if (item.language == "en") {
            this.message_en.id = item.id
            this.message_en.message = item.message
            this.message_en.busy = false
            this.message_en.active = item.active
            this.activeSMS = Boolean(item.active)
          } else {
            this.message_es.id = item.id
            this.message_es.message = item.message
            this.message_es.busy = false
            this.message_es.active = item.active
          }
        })
      } catch (error) {
        this.showErrorSwal(error)
      }
    },
    enableText() {
      this.disableText = false
    },
    reset() {
      this.appMessage()
    },
    async activeSms() {
      const response = await this.showConfirmSwal()
      if (!response.value) {
        this.activeSMS = !this.activeSMS
        return
      }
      try {
        const messages = await Promise.all(
          [this.message_en, this.message_es].map((item) => {
            return {
              id: item.id,
              message: item.message,
              active: this.activeSMS,
            }
          })
        )
        await MessageService.updateNoAnswerSms({ messages })
        this.showSuccessSwal()
      } catch (error) {
        this.showErrorSwal(error)
      }
    },
    async submit() {
      const isValidated = await this.$refs.formMessage.validate()
      if (!isValidated) {
        return
      }
      const messages = [this.message_en, this.message_es]
      try {
        await MessageService.updateNoAnswerSms({ messages })
        this.showSuccessSwal()
        this.disableText = true
      } catch (error) {
        this.showErrorSwal(error)
      }
    },
  },
}
</script>
<style scoped>
.iconPadding {
  padding: 2px 0px 0px 10px;
}

.cardPadding {
  padding: 3px 0 3px 10px;
}
@media (max-width: 575.98px) {
  .widthInput2 {
    width: 60%;
  }
  .widthInput1 {
    width: 50%;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .widthInput2 {
    width: 50%;
  }
  .widthInput1 {
    width: 47%;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .widthInput2 {
    min-width: 67%;
  }
  .widthInput1 {
    width: 65%;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .widthInput2 {
    min-width: 62%;
  }
  .widthInput1 {
    width: 60%;
  }
  .marginR {
    margin-right: 300px;
  }
}
@media (min-width: 1200px) {
  .widthInput2 {
    min-width: 74%;
  }
  .widthInput1 {
    width: 73%;
  }
  .marginR {
    margin-right: 250px;
  }
}
</style>
